import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
    onClickClose: () => void;
    onClickSubmit: (value: number) => void;
}

function EditValueDialog(props: Props) {
    const { onClickClose, onClickSubmit } = props;
    const [value, setValue] = useState('');

    return (
        <Dialog open={true} onClose={onClickClose}>
            <DialogTitle>Edit</DialogTitle>

            <DialogContent>
                <TextField
                    value={value}
                    margin='dense'
                    label='Value'
                    type='number'
                    fullWidth
                    variant='standard'
                    onChange={(e) => setValue(e.target.value)}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClickClose}>Cancel</Button>
                <Button onClick={() => onClickSubmit(Number(value))}>Edit</Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(EditValueDialog);
