import { IRowType } from '../../components/section/Section';
import ACTIONS from './actionTypes';
import { store } from '../configureStore';

export function addLiability(row: IRowType) {
    const liabilities: IRowType[] = store.getState().liabilities;

    return {
        type: ACTIONS.ADD_LIABILITY,
        data: [...liabilities, row],
    };
}

export function removeLiability(index: number) {
    const liabilities: IRowType[] = store.getState().liabilities;

    return {
        type: ACTIONS.REMOVE_LIABILITY,
        data: liabilities.filter((v, i) => i !== index),
    };
}

export function resetLiabilities() {
    return { type: ACTIONS.RESET_LIABILITIES, data: [] };
}
