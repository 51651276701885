import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface Props {
    onClickClose: () => void;
    onClickSubmit: (value: number, name: string) => void;
}

function AddRecordDialog(props: Props) {
    const { onClickClose, onClickSubmit } = props;

    const [data, setData] = useState({ name: '', value: '' });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData((p) => ({ ...p, [name]: value }));
    };

    return (
        <Dialog open={true} onClose={onClickClose}>
            <DialogTitle>Add Record</DialogTitle>

            <DialogContent>
                <TextField
                    value={data.name}
                    margin='dense'
                    name='name'
                    label='Name'
                    type='text'
                    fullWidth
                    variant='standard'
                    onChange={handleChange}
                />
                <TextField
                    value={data.value}
                    margin='dense'
                    name='value'
                    label='Value'
                    type='number'
                    fullWidth
                    variant='standard'
                    onChange={handleChange}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClickClose}>Cancel</Button>
                <Button onClick={() => onClickSubmit(Number(data.value), data.name)}>Add Record</Button>
            </DialogActions>
        </Dialog>
    );
}

export default React.memo(AddRecordDialog);
