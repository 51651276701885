import { IRowType } from '../../components/section/Section';
import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function liabilitiesReducer(state = initialState.liabilities, action: { type: ACTIONS; data: IRowType[] }) {
    switch (action.type) {
        case ACTIONS.ADD_LIABILITY:
        case ACTIONS.REMOVE_LIABILITY:
            return action.data;

        case ACTIONS.RESET_LIABILITIES:
            return initialState.liabilities;

        default:
            return state;
    }
}

export default liabilitiesReducer;
