import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/Home';
import { persist, store } from './redux/configureStore';

const theme = createTheme({
    palette: {
        primary: {
            main: '#004e98',
            // main: '#ff6700',
        },
        secondary: {
            main: '#3a6ea5',
        },
    },
    typography: {
        h1: { fontSize: '3rem' },
        h2: { fontSize: '2.5rem' },
        h3: { fontSize: '2rem' },
        h4: { fontSize: '1.75rem' },
        h5: { fontSize: '1.5rem' },
        h6: { fontSize: '1.25rem' },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persist}>
                    <Navbar />
                    <Home />
                </PersistGate>
            </Provider>
        </ThemeProvider>
    );
}

export default App;
