import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRowType } from '../../components/section/Section';
import FirebaseApp from '../../helpers/FirebaseApp';
import { addAsset, removeAsset } from '../../redux/actions/assets.action';
import { editCash } from '../../redux/actions/cash.action';
import { addExpense, removeExpense } from '../../redux/actions/expenses.action';
import { addLiability, removeLiability } from '../../redux/actions/liabilities.action';
import { addPassiveIncome, removePassiveIncome } from '../../redux/actions/passiveIncome.action';
import { editSalary } from '../../redux/actions/salary.action';
import { store } from '../../redux/configureStore';
import { RootState } from '../../redux/reducers';

export type IModalTypes = 'PASSIVE_INCOME' | 'EXPENSE' | 'ASSET' | 'LIABILITY' | 'SALARY' | 'CASH' | null;

const useHome = () => {
    const [modalType, setModalType] = useState<IModalTypes>(null);
    const { passiveIncome, expenses, assets, liabilities, cash, salary } = useSelector((s: RootState) => s);

    const totalPassiveIncome = useMemo(() => sum(passiveIncome), [passiveIncome]);
    const totalExpenses = useMemo(() => sum(expenses), [expenses]);
    const totalAssets = useMemo(() => sum(assets), [assets]);
    const totalLiabilities = useMemo(() => sum(liabilities), [liabilities]);

    const income = useMemo(() => [{ name: 'Salary', value: salary, salary: true }, ...passiveIncome], [passiveIncome, salary]);
    const totalIncome = useMemo(() => totalPassiveIncome + salary, [totalPassiveIncome, salary]);
    const missingPassiveIncome = useMemo(() => (totalExpenses - totalPassiveIncome).toFixed(2), [totalPassiveIncome, totalExpenses]);

    const openModal = (type: IModalTypes) => setModalType(type);
    const closeModal = () => setModalType(null);

    const handleSubmitModal = (value: number, name: string) => {
        switch (modalType) {
            case 'PASSIVE_INCOME':
                store.dispatch(addPassiveIncome({ name, value }));
                break;
            case 'EXPENSE':
                store.dispatch(addExpense({ name, value }));
                break;
            case 'ASSET':
                store.dispatch(addAsset({ name, value }));
                break;
            case 'LIABILITY':
                store.dispatch(addLiability({ name, value }));
                break;
            case 'SALARY':
                store.dispatch(editSalary(value));
                break;
            case 'CASH':
                store.dispatch(editCash(value));
                break;

            default:
                break;
        }

        closeModal();
    };
    const handleRemoveModal = (type: IModalTypes, index: number) => {
        switch (type) {
            case 'PASSIVE_INCOME':
                store.dispatch(removePassiveIncome(index));
                break;
            case 'EXPENSE':
                store.dispatch(removeExpense(index));
                break;
            case 'ASSET':
                store.dispatch(removeAsset(index));
                break;
            case 'LIABILITY':
                store.dispatch(removeLiability(index));
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        FirebaseApp.analytics().screenView('Home');
    }, []);

    return {
        cash,
        expenses,
        income,
        assets,
        liabilities,
        totalPassiveIncome,
        totalIncome,
        totalExpenses,
        totalAssets,
        totalLiabilities,
        missingPassiveIncome,
        handleSubmitModal,
        openModal,
        closeModal,
        modalType,
        handleRemoveModal,
    };
};

export default useHome;

const sum = (rows: IRowType[]) => rows.reduce((p, v) => (p += v.value), 0);
