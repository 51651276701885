import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function cashReducer(state = initialState.cash, action: { type: ACTIONS; data: number }) {
    switch (action.type) {
        case ACTIONS.EDIT_CASH:
            return action.data;

        case ACTIONS.RESET_CASH:
            return initialState.cash;

        default:
            return state;
    }
}

export default cashReducer;
