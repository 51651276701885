import { IRowType } from '../../components/section/Section';

interface IState {
    passiveIncome: IRowType[];
    expenses: IRowType[];
    assets: IRowType[];
    liabilities: IRowType[];
    cash: number;
    salary: number;
}

const initialState: IState = {
    passiveIncome: [],
    expenses: [],
    assets: [],
    liabilities: [],
    cash: 0,
    salary: 0,
};

export default initialState;
