import React from 'react';
import { Grid, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Paper, Button, Typography, IconButton, Stack } from '@mui/material';
import styles from './Section.module.scss';
import { formatEGP } from '../../helpers/numbers';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

export interface IRowType {
    name: string;
    value: number;
    salary?: boolean;
}

interface Props {
    title: string;
    rows: IRowType[];
    total: number;
    onClickAdd?: React.MouseEventHandler<HTMLButtonElement>;
    onEditSalary?: React.MouseEventHandler<HTMLButtonElement>;
    onDeleteRow?: (index: number) => void;
}

const Section = (props: Props) => {
    const { title, rows, total, onClickAdd, onEditSalary, onDeleteRow = () => {} } = props;

    return (
        <Grid item xl={5.75} md={5.75} sm={12} xs={12} className={styles.container}>
            <Typography variant='h2'>{title}</Typography>

            <TableContainer component={Paper}>
                <Table>
                    {/* Head */}
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>Name</Typography>
                            </TableCell>
                            <TableCell align='right'>
                                <Typography>Value</Typography>
                            </TableCell>
                            {/* <TableCell align='right'>
                                <Typography>Action</Typography>
                            </TableCell> */}
                        </TableRow>
                    </TableHead>

                    {/* Body Rows */}
                    <TableBody>
                        {rows.map((row, i) => (
                            <TableRow key={`${i}`}>
                                <TableCell scope='row'>
                                    <Typography>{row.name}</Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    <Stack direction='row' justifyContent='flex-end' alignItems='center'>
                                        <Typography>{formatEGP(row.value)}</Typography>

                                        <IconButton size='small' color='inherit' onClick={row.salary ? onEditSalary : () => onDeleteRow(i)}>
                                            {row.salary ? <EditIcon color='primary' /> : <RemoveIcon color='error' />}
                                        </IconButton>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Empty Rows */}
                        {rows.length === 0 && (
                            <TableRow>
                                <TableCell align='center' colSpan={2}>
                                    <Typography>No data available</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>

                    {/* Caption */}
                    <caption>
                        <Typography>{formatEGP(total)}</Typography>
                    </caption>
                </Table>
            </TableContainer>

            {onClickAdd && (
                <Button variant='contained' className={styles.button} onClick={onClickAdd}>
                    Add Record
                </Button>
            )}
        </Grid>
    );
};

export default React.memo(Section);
