import { IRowType } from '../../components/section/Section';
import ACTIONS from './actionTypes';
import { store } from '../configureStore';

export function addExpense(row: IRowType) {
    const expenses: IRowType[] = store.getState().expenses;

    return {
        type: ACTIONS.ADD_EXPENSE,
        data: [...expenses, row],
    };
}

export function removeExpense(index: number) {
    const expenses: IRowType[] = store.getState().expenses;

    return {
        type: ACTIONS.REMOVE_EXPENSE,
        data: expenses.filter((v, i) => i !== index),
    };
}

export function resetExpenses() {
    return { type: ACTIONS.RESET_EXPENSES, data: [] };
}
