import { IRowType } from '../../components/section/Section';
import ACTIONS from './actionTypes';
import { store } from '../configureStore';

export function addAsset(row: IRowType) {
    const assets: IRowType[] = store.getState().assets;

    return {
        type: ACTIONS.ADD_ASSET,
        data: [...assets, row],
    };
}

export function removeAsset(index: number) {
    const assets: IRowType[] = store.getState().assets;

    return {
        type: ACTIONS.REMOVE_ASSET,
        data: assets.filter((v, i) => i !== index),
    };
}

export function resetAssets() {
    return { type: ACTIONS.RESET_ASSETS, data: [] };
}
