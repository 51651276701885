import { IRowType } from '../../components/section/Section';
import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function assetsReducer(state = initialState.assets, action: { type: ACTIONS; data: IRowType[] }) {
    switch (action.type) {
        case ACTIONS.ADD_ASSET:
        case ACTIONS.REMOVE_ASSET:
            return action.data;

        case ACTIONS.RESET_ASSETS:
            return initialState.assets;

        default:
            return state;
    }
}

export default assetsReducer;
