import { IRowType } from '../../components/section/Section';
import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function expensesReducer(state = initialState.expenses, action: { type: ACTIONS; data: IRowType[] }) {
    switch (action.type) {
        case ACTIONS.ADD_EXPENSE:
        case ACTIONS.REMOVE_EXPENSE:
            return action.data;

        case ACTIONS.RESET_EXPENSES:
            return initialState.expenses;

        default:
            return state;
    }
}

export default expensesReducer;
