import React from 'react';
import { LinearProgressProps, Typography, LinearProgress, Stack, linearProgressClasses, styled, Tooltip } from '@mui/material';
import { formatEGP } from '../../helpers/numbers';

interface Props extends LinearProgressProps {
    currentValue: number;
    totalValue: number;
    showCurrent?: boolean;
    showTotal?: boolean;
    toolTip?: string;
}

function LinearProgressWithLabel(props: Props) {
    const { showCurrent, showTotal, currentValue, totalValue, toolTip, ...rest } = props;
    const percent = Math.round((currentValue / totalValue) * 100);

    return (
        <Stack direction='row' spacing={2} alignItems='center' width='40rem' maxWidth='100%' flexWrap='wrap'>
            {showCurrent && <Typography>{formatEGP(currentValue)}</Typography>}

            <Tooltip title={toolTip} arrow>
                <BorderLinearProgress variant='determinate' value={percent} {...rest} />
            </Tooltip>

            <Typography>{`${showTotal ? formatEGP(totalValue) + ' /' : ''} ${percent}%`}</Typography>
        </Stack>
    );
}

export default React.memo(LinearProgressWithLabel);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12.5,
    borderRadius: 7.5,
    flex: 1,

    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 7.5,
        backgroundColor: theme.palette.primary,
    },
}));
