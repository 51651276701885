import { IRowType } from '../../components/section/Section';
import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function incomeReducer(state = initialState.passiveIncome, action: { type: ACTIONS; data: IRowType[] }) {
    switch (action.type) {
        case ACTIONS.ADD_PASSIVE_INCOME:
        case ACTIONS.REMOVE_PASSIVE_INCOME:
            return action.data;

        case ACTIONS.RESET_PASSIVE_INCOME:
            return initialState.passiveIncome;

        default:
            return state;
    }
}

export default incomeReducer;
