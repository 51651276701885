/* eslint-disable no-unused-vars */

enum ACTIONS {
    // Passive Income
    ADD_PASSIVE_INCOME = 'ADD_PASSIVE_INCOME',
    REMOVE_PASSIVE_INCOME = 'REMOVE_PASSIVE_INCOME',
    RESET_PASSIVE_INCOME = 'RESET_PASSIVE_INCOME',

    // Expenses
    ADD_EXPENSE = 'ADD_EXPENSE',
    REMOVE_EXPENSE = 'REMOVE_EXPENSE',
    RESET_EXPENSES = 'RESET_EXPENSES',

    // Assets
    ADD_ASSET = 'ADD_ASSET',
    REMOVE_ASSET = 'REMOVE_ASSET',
    RESET_ASSETS = 'RESET_ASSETS',

    // Liabilities
    ADD_LIABILITY = 'ADD_LIABILITY',
    REMOVE_LIABILITY = 'REMOVE_LIABILITY',
    RESET_LIABILITIES = 'RESET_LIABILITIES',

    // Cash
    EDIT_CASH = 'EDIT_CASH',
    RESET_CASH = 'RESET_CASH',

    // Salary
    EDIT_SALARY = 'EDIT_SALARY',
    RESET_SALARY = 'RESET_SALARY',
}

export default ACTIONS;
