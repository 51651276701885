import ACTIONS from './actionTypes';

export function editSalary(newValue: number) {
    return {
        type: ACTIONS.EDIT_SALARY,
        data: newValue,
    };
}
export function resetSalary() {
    return { type: ACTIONS.RESET_SALARY, data: 0 };
}
