import React from 'react';
import { Grid, Container, Stack, Typography, Divider, useTheme, useMediaQuery, Box, IconButton } from '@mui/material';
import Section from '../../components/section/Section';
import useHome from './Home.hooks';
import styles from './Home.module.scss';
import LinearProgressWithLabel from '../../components/linearProgressWithLabel/LinearProgressWithLabel';
import { formatEGP } from '../../helpers/numbers';
import AddRecordDialog from '../../components/dialog/AddRecordDialog';
import EditValueDialog from '../../components/dialog/EditValueDialog';
import EditIcon from '@mui/icons-material/Edit';

const Home = () => {
    const {
        cash,
        expenses,
        income,
        assets,
        liabilities,
        totalPassiveIncome,
        totalIncome,
        totalExpenses,
        totalAssets,
        totalLiabilities,
        missingPassiveIncome,
        handleSubmitModal,
        openModal,
        closeModal,
        modalType,
        handleRemoveModal,
    } = useHome();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Container className={styles.container} maxWidth='xl'>
            <Title />

            <Stack
                direction={isMobile ? 'column' : 'row'}
                justifyContent='center'
                alignItems='center'
                marginY={2}
                spacing={3}
                flexWrap='wrap'
                divider={isMobile ? <></> : <Divider orientation='vertical' flexItem />}
            >
                <Box maxWidth={'100%'}>
                    <LinearProgressWithLabel
                        showCurrent
                        showTotal
                        currentValue={totalPassiveIncome}
                        totalValue={totalExpenses}
                        toolTip={'Passive Income / Total Expenses'}
                    />

                    <Typography sx={{ textAlign: 'center' }}>
                        You need <b>{missingPassiveIncome}/Month</b> more in your passive income to reach the financial freedom
                    </Typography>
                </Box>

                <Cash cash={cash} totalIncome={totalIncome} totalExpenses={totalExpenses} onEditCash={() => openModal('CASH')} />
            </Stack>

            <Grid container justifyContent='space-between'>
                <Section
                    title='Income'
                    rows={income}
                    total={totalIncome}
                    onClickAdd={() => openModal('PASSIVE_INCOME')}
                    onEditSalary={() => openModal('SALARY')}
                    onDeleteRow={(i) => handleRemoveModal('PASSIVE_INCOME', i - 1)}
                />
                <Section
                    title='Expenses'
                    rows={expenses}
                    total={totalExpenses}
                    onClickAdd={() => openModal('EXPENSE')}
                    onDeleteRow={(i) => handleRemoveModal('EXPENSE', i)}
                />
                <Section
                    title='Assets'
                    rows={assets}
                    total={totalAssets}
                    onClickAdd={() => openModal('ASSET')}
                    onDeleteRow={(i) => handleRemoveModal('ASSET', i)}
                />
                <Section
                    title='Liabilities'
                    rows={liabilities}
                    total={totalLiabilities}
                    onClickAdd={() => openModal('LIABILITY')}
                    onDeleteRow={(i) => handleRemoveModal('LIABILITY', i)}
                />
            </Grid>

            <Box marginY={'2rem'}>
                <Typography>* The website does not store any data ... All your data is just cached in your browser</Typography>
                <Typography>* The used currency is $ but it's just a placeholder which can be applied for any currency</Typography>

                <Typography>
                    * This website is made from the understanding of{' '}
                    <Typography component='a' href='https://www.richdad.com/classic' target='_blank'>
                        the CashFlow game
                    </Typography>{' '}
                    introduced by{' '}
                    <Typography component='a' href='https://en.wikipedia.org/wiki/Robert_Kiyosaki' target='_blank'>
                        Robert Kiyosaki
                    </Typography>{' '}
                    in his book{' '}
                    <Typography component='a' href='https://en.wikipedia.org/wiki/Rich_Dad_Poor_Dad' target='_blank'>
                        Rich Dad Poor Dad
                    </Typography>
                </Typography>

                <Typography>
                    * This website is introduced by{' '}
                    <Typography component='a' href='https://codxy.com' target='_blank'>
                        https://codxy.com
                    </Typography>{' '}
                    Feel free to contact us if you have any suggestions
                </Typography>
            </Box>

            {modalType === 'SALARY' || modalType === 'CASH' ? (
                <EditValueDialog onClickClose={closeModal} onClickSubmit={(v) => handleSubmitModal(v, '')} />
            ) : (
                modalType !== null && <AddRecordDialog onClickClose={closeModal} onClickSubmit={handleSubmitModal} />
            )}
        </Container>
    );
};

export default React.memo(Home);

const Title = () => (
    <Typography
        variant='h5'
        sx={{
            mt: 2,
            textAlign: 'center',
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            textTransform: 'uppercase',
        }}
    >
        Increase your passive income to escape the rat race
    </Typography>
);

interface CashProps {
    cash: number;
    totalIncome: number;
    totalExpenses: number;
    onEditCash?: () => void;
}
const Cash = ({ totalIncome, totalExpenses, cash, onEditCash }: CashProps) => (
    <Stack justifyContent='space-between' width='25rem' maxWidth={'100%'}>
        <Row title='Cash' value={cash} lg bold onEditCash={onEditCash} />
        <Row title='Total Income' value={totalIncome} />
        <Row title='Total Expenses' value={-totalExpenses} />

        <Divider flexItem />
        <Row title='Payday' value={totalIncome - totalExpenses} bold />
    </Stack>
);

interface RowProps {
    lg?: boolean;
    bold?: boolean;
    title: string;
    value: number;
    onEditCash?: () => void;
}
const Row = ({ title, value, lg, bold, onEditCash }: RowProps) => (
    <Stack direction='row' justifyContent='space-between'>
        <Typography variant={lg ? 'h5' : 'h6'} fontWeight={bold ? 'bold' : ''}>
            {title}
        </Typography>

        <Stack direction='row'>
            <Typography variant={lg ? 'h5' : 'h6'} fontWeight={bold ? 'bold' : ''}>
                {formatEGP(value)}
            </Typography>

            {onEditCash && (
                <IconButton size='small' color='inherit' onClick={onEditCash}>
                    <EditIcon color='primary' />
                </IconButton>
            )}
        </Stack>
    </Stack>
);
