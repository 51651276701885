import ACTIONS from './actionTypes';

export function editCash(newValue: number) {
    return {
        type: ACTIONS.EDIT_CASH,
        data: newValue,
    };
}

export function resetCash() {
    return { type: ACTIONS.RESET_CASH, data: 0 };
}
