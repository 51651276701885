import { combineReducers } from 'redux';

import passiveIncome from './passiveIncome.reducer';
import expenses from './expenses.reducer';
import assets from './assets.reducer';
import liabilities from './liabilities.reducer';
import salary from './salary.reducer';
import cash from './cash.reducer';

const rootReducer = combineReducers({
    passiveIncome,
    expenses,
    assets,
    liabilities,
    salary,
    cash,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
