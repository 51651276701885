import { store } from '../configureStore';
import { resetAssets } from './assets.action';
import { resetCash } from './cash.action';
import { resetExpenses } from './expenses.action';
import { resetLiabilities } from './liabilities.action';
import { resetPassiveIncome } from './passiveIncome.action';
import { resetSalary } from './salary.action';

export function resetAllReduxStore() {
    store.dispatch(resetAssets());
    store.dispatch(resetCash());
    store.dispatch(resetExpenses());
    store.dispatch(resetLiabilities());
    store.dispatch(resetPassiveIncome());
    store.dispatch(resetSalary());
}
