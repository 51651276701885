import { IRowType } from '../../components/section/Section';
import ACTIONS from './actionTypes';
import { store } from '../configureStore';

export function addPassiveIncome(row: IRowType) {
    const passiveIncome: IRowType[] = store.getState().passiveIncome;

    return {
        type: ACTIONS.ADD_PASSIVE_INCOME,
        data: [...passiveIncome, row],
    };
}

export function removePassiveIncome(index: number) {
    const passiveIncome: IRowType[] = store.getState().passiveIncome;

    return {
        type: ACTIONS.REMOVE_PASSIVE_INCOME,
        data: passiveIncome.filter((v, i) => i !== index),
    };
}

export function resetPassiveIncome() {
    return { type: ACTIONS.RESET_PASSIVE_INCOME, data: [] };
}
