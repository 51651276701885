import ACTIONS from '../actions/actionTypes';
import initialState from './initialState';

function salaryReducer(state = initialState.salary, action: { type: ACTIONS; data: number }) {
    switch (action.type) {
        case ACTIONS.EDIT_SALARY:
            return action.data;

        case ACTIONS.RESET_SALARY:
            return initialState.salary;

        default:
            return state;
    }
}

export default salaryReducer;
